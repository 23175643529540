$().ready(function() {

    // $('.nav ul li').hover(function () {
    //     $('.nav ul li').removeClass('lihover'),
    //     $(this).addClass('lihover');
    // })
    $(".jmdp ul li a").hover(function () {
            $(this).find('.front').animate({top:"246px"}),
            $(this).find('.back').animate({top:"0px"});
        },function(){
            $(this).find('.front').animate({top:"0px"}),
            $(this).find('.back').animate({top:"-246px"});
    });


});

$(window).scroll(function() {
    var h = $(this).scrollTop(); //获得滚动条距top的高度
    //alert(h); 
    if (h > 90) {
        $(".iabout h3").slideDown(500).fadeIn();
    }
    if (h > 100) {
        $(".iabout p").animate({opacity:'1'},1000);
    }
    if (h > 1000) {
        $(".pro_rec_l").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 1000) {
        $(".pro_rec_r img").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 2100) {
        $(".choose_left").fadeIn(200).animate({left:'0'},1000);
    }
    if (h > 2100) {
        $(".choose_right").fadeIn(200).animate({left:'0'},1000);
    }
    if (h > 2400) {
        $(".advantage h2").fadeIn(1000);
    }
    if (h > 2400) {
        $(".advantage h5").fadeIn(200).animate({top:'0'},1000);
    }
    if (h > 2600) {
        $(".advantage_right_t .p1").fadeIn(200).animate({left:'0'},1000);
    }
    if (h > 2650) {
        $(".advantage_right_t .p2").fadeIn(200).animate({left:'0'},1000);
    }
    if (h > 2950) {
        $(".advantage .clear li img").css('transform','rotateY(360deg)');
    }
    if (h > 2950) {
        $(".icontact2_t img").css('transform','rotateY(360deg)');
    }
    if (h > 3300) {
        $(".experience_t_t").fadeIn(200).animate({top:'0'},1000);
    }
    if (h > 3700) {
        $(".news_right").fadeIn(200).animate({left:'0'},1000);
    }
});

$(function() {
    new Slider($("#banner_tabs"), {
        time: 5e3,
        delay: 400,
        event: "hover",
        auto: !0,
        mode: "fade",
        controller: $("#bannerCtrl"),
        activeControllerCls: "active"
    })
});

        